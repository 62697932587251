import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'

export const formatDate = (date: Date | string, formatString: string, lang?: string) => {
  const jsDate = new Date(date)
  const locale = lang === 'ru' ? ru : undefined
  return format(jsDate, formatString, { locale })
}

export const toHumanDate = (date: Date | string) => {
  return formatDate(date, 'dd MMMM yyyy')
}
